let API_URL_TEMP = process.env?.REACT_APP_API_URL;

if(process.env?.REACT_APP_ENV === 'DEV'){
    console.log('DEV MODE ENGAGED');
    API_URL_TEMP = process.env?.REACT_APP_API_URL_DEV;
}

console.log('ENV VAR');
console.log(API_URL_TEMP);

export const API_URL=API_URL_TEMP;
