import { Canvas } from "@react-three/fiber";
import { Physics, useBox, usePlane } from "@react-three/cannon";
import { useState, useEffect } from "react";

function Box({ position }) {
  const [ref] = useBox(() => ({ mass: 1, position }));

  return (
    <mesh ref={ref} castShadow>
      <boxGeometry />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

function Plane({ rotation = [0, 0, 0], position = [0, 0, 0], name }) {
  const [ref] = usePlane(() => ({
    rotation,
    position,
    type: "Static",
    name,
  }));

  return (
    <mesh ref={ref} receiveShadow>
      <planeGeometry args={[5, 5]} />
      <meshStandardMaterial color="gray" side={2} />
    </mesh>
  );
}

function BoxContainer() {
  return (
    <>
      <Plane
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        name="floor"
      />
      <Plane position={[0, 2.5, -2.5]} rotation={[0, 0, 0]} />
      <Plane position={[0, 2.5, 2.5]} rotation={[0, Math.PI, 0]} />
      <Plane position={[-2.5, 2.5, 0]} rotation={[0, Math.PI / 2, 0]} />
      <Plane position={[2.5, 2.5, 0]} rotation={[0, -Math.PI / 2, 0]} />
    </>
  );
}

export default function CubeDrop() {
  const [targetBoxCount, setTargetBoxCount] = useState(25); // This can change dynamically
  const [boxes, setBoxes] = useState(
    Array.from({ length: 25 }, (_, i) => ({
      id: i,
      position: [0, 5 + i * 1.2, 0],
    }))
  );

  // Simulate targetBoxCount changing dynamically (for example purposes)
  useEffect(() => {
    const interval = setInterval(() => {
      setTargetBoxCount((prev) => Math.max(0, prev - 1)); // Decrease dynamically
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Sync the number of boxes with targetBoxCount
  useEffect(() => {
    setBoxes((prev) => {
      if (prev.length < targetBoxCount) {
        // Add new boxes
        return [
          ...prev,
          { id: prev.length, position: [0, 5 + prev.length * 1.2, 0] },
        ];
      } else {
        // Remove excess boxes
        return prev.slice(0, targetBoxCount);
      }
    });
  }, [targetBoxCount]);

  return (
    <Canvas shadows camera={{ position: [0, 10, 10], fov: 50 }}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} castShadow />
      <Physics gravity={[0, -9.81, 0]}>
        <BoxContainer />
        {boxes.map((box) => (
          <Box key={box.id} position={box.position} />
        ))}
      </Physics>
    </Canvas>
  );
}
