import { lazy, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { get } from 'config/axiosConfig';
import getLyocCookie from 'Cookies';

import Login from 'components/Login';
import Register from 'components/Register';

import './App.css';
import CubeDrop from 'test/CubeDrop';

const Game = lazy(() => import('./components/game/Game'));

function App() {
  const [token, setToken] = useState(getLyocCookie()); // const cookieName = 'lyoc_cookie';
  const [defaultName, setDefaultName] = useState('');
  const [defaultColor, setDefaultColor] = useState('');

  useEffect(() => {
    if(getLyocCookie()){
      setToken(getLyocCookie());
    }
  }, []);

  async function getUserMeta(){
    let response = await get('/username'); // GET PLAYER NAME && DEFAULT COLOR
    if(!response){
      return;
    }
    console.log(response.data.username + ' ' + response.data.default_color)
    setDefaultName(response.data.username);
    setDefaultColor(response.data.default_color);
  }

  useEffect(()=>{
    if(token && token !== ''){
      getUserMeta();
    }
  }, [token]);

  if(!token && (!defaultName && !defaultColor)){
    return(
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Login setToken={setToken}/>} />
          <Route path="register/*" element={<Register />} />
          <Route path="CubeDrop" element={<CubeDrop />} />
      </Routes>
      </BrowserRouter>
    );
  }

  return (<Game defaultName={defaultName} defaultColor={defaultColor}/>)
}

export default App;
