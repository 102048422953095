import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUnAuth } from "config/axiosConfig";
import { setLyocCookie } from "Cookies";

interface LoginProps {
  setToken: (token: string) => void;
}

const Login = ({ setToken }: LoginProps) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  async function loginUser(username: string, password: string): Promise<string | null> {
    const loginParams = `login?username=${username}&password=${password}`;

    try {
      const response = await getUnAuth(loginParams);
      if (!response) {
        return null;
      }
      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = await loginUser(username, password);
    if (token == null) {
      alert("Unable to login. Are the email and password correct?");
      return;
    }

    // Redundant, but so we can load the token from the cookie on reload
    setLyocCookie(token);
    setToken(token);
  };

  const openLinkInNewTab = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.preventDefault();
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };

  const handleNavigateToRegister = async (e: any) => {
    e.preventDefault();
    navigate("../register", { replace: true }); 
  }

  const handleNavigateCubeDrop = async (e: any) => {
    e.preventDefault();
    navigate("../CubeDrop", { replace: true }); 
  }

  return (
    <div className="App">
      <div className="mainBody">
        <h2>Last Year of Carbon</h2>

        <button>Watch The Board Game Tutorial</button>

        <button>Read the Rulebook</button>

        <button onClick={(e) => openLinkInNewTab(e, "https://lyoc.shop")}>
          Buy A Copy
        </button>

        {/*<button onClick={(e) => handleNavigateCubeDrop(e)}>CubeDrop</button>*/}

        <form onSubmit={handleSubmit} className="login-form">
          <h3>Login to play</h3>
          <label>
            <p>Email or Username</p>
            <input type="text" onChange={(e) => setUsername(e.target.value)} />
          </label>
          <label>
            <p>Password</p>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className="link-wrapper">
          <button onClick={(e) => {handleNavigateToRegister(e)}}>Register</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
